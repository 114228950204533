import React, {useState} from "react";
import { Container } from "react-bootstrap";
import HorizontalSpacer from "./horizontal-spacer";
import {Amplify, Auth} from "aws-amplify";
import { navigate } from "gatsby"
import BlockTitle from "./block-title";
import logoImage from "../assets/images/logo.png";

const getAmplifyConfig = async () => {
    let domain = "fanster.ai";
    // ToDo: Vladimir, I can't use window.location.hostname
    // let domain = window.location.hostname;
    // if (window.location.hostname === "localhost") {
    //     domain = "fanster.ai";
    // }
    return (await fetch(`https://${domain}/amplify-config.json`)).json();
}

Amplify.configure(await getAmplifyConfig());

const AUTH_STATE = {
    INPUT_EMAIL: "input_email",
    INPUT_AUTH_CODE: "input_auth_code"
}

const AUTH_INPUT_PLACEHOLDER = {
    EMAIL: "Confirm your email",
    AUTH_CODE: "Enter the code sent to "
}

const AUTH_INPUT_SUBMIT_BUTTON = {
    EMAIL: "Confirm",
    AUTH_CODE: "Access"
}

let suppliedEmail;
let authenticatingUser;

const AuthComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [inError, setInError] = useState(false);
    const [authenticationState, setAuthenticationState] = useState(AUTH_STATE.INPUT_EMAIL);
    const [inputFieldValue, setInputFieldValue] = useState('');

    const signIn = async (email) => {
        try {
            return await Auth.signIn(email.toLowerCase(), "");
        } catch (error) {
            return null;
        }
    }

    const confirm = async (user, validationCode) => {
        if (user && user.challengeName === 'CUSTOM_CHALLENGE') {
            return await Auth.sendCustomChallengeAnswer(user, validationCode)
        }
    }

    const signUp = async (suppliedEmail) => {
        try {
            return await Auth.signUp({
                username: (suppliedEmail.toLowerCase()),
                password: "oQf01Ft(&38A"
            });

        } catch (error) {
            return null
        }
    }

    const handleAccessRequest = async (event) => {
        event.preventDefault();

        if (!inputFieldValue || inputFieldValue === "") return;

        if (authenticationState === AUTH_STATE.INPUT_EMAIL && !validateEmail(inputFieldValue)) {
            setInError(true);
            return;
        }

        if (authenticationState === AUTH_STATE.INPUT_EMAIL) {
            suppliedEmail = inputFieldValue;

            setIsLoading(true);

            await signUp(suppliedEmail);

            setAuthenticationState(AUTH_STATE.INPUT_AUTH_CODE);
            setIsLoading(false);
            setInputFieldValue("");
            authenticatingUser = await signIn(suppliedEmail);

        } else {
            setIsLoading(true);

            let authenticatedUser;

            authenticatedUser = await confirm(authenticatingUser, inputFieldValue);

            setIsLoading(false);

            if (authenticatedUser && authenticatedUser.signInUserSession) {
                navigate("/introduction")
            } else {
                setInError(true);
            }
        }
    }

    const handleFieldChange = (event) => {
        setInError(false);
        setInputFieldValue(event.target.value);
    }

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(String(email).toLowerCase());
    }

    return (
        <section className="subscribe-style-one center-children-horizontal-vertical">
            <Container>
                <BlockTitle
                    textAlign="center"
                    image={logoImage}
                    title={``}
                    showImage={true}
                />
                <h3 className="text-center">
                    Enter your email in order to access Fanster.
                </h3>
                <HorizontalSpacer height={50} />
                <form onSubmit={handleAccessRequest} className="subscribe-form contact-form-vaidated">
                    <input onChange={handleFieldChange} value={inputFieldValue} className={inError ? 'error' : ''} type="text" name="emailAndCode" placeholder={authenticationState === AUTH_STATE.INPUT_EMAIL ? AUTH_INPUT_PLACEHOLDER.EMAIL : AUTH_INPUT_PLACEHOLDER.AUTH_CODE + suppliedEmail} />
                    <button disabled={isLoading} onClick={handleAccessRequest} className={isLoading ? "button-loading" : ''} type="submit">{authenticationState === AUTH_STATE.INPUT_EMAIL ? AUTH_INPUT_SUBMIT_BUTTON.EMAIL : AUTH_INPUT_SUBMIT_BUTTON.AUTH_CODE}</button>
                </form>
                <div className="result"></div>
            </Container>
        </section>
    );
};

export default AuthComponent;
