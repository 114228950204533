import React from "react"
import Layout from "../components/layout"
import AuthComponent from "../components/auth"
import Seo from "../components/seo"

const Access = () => {
    return (
        <Layout pageTitle="Access">
            <Seo title="Access" />
            {/*<HeaderOne />*/}
            <AuthComponent />
        </Layout>
    )
}

export default Access
