import React from "react"

const BlockTitle = ({ textAlign, image, title, showImage }) => {
  return (
    <div className={`block-title text-${textAlign}`}>
      {showImage ? <img height={125} src={image} alt="Title" /> : ''}
      <h2>{title}</h2>
    </div>
  )
}

export default BlockTitle
